
// export default ({ app, error, route }, inject) => {
//     console.log('navigator', navigator);

//     // Prevent the script from running on the 404 page
//     if (route.path === '/pages/404') {
//         return;
//     }
    
//     if (navigator.connection) {
//         const { effectiveType } = navigator.connection;

//         console.log("effectiveType#@", effectiveType);
        

//         // Detect if the connection is slow
//         if (effectiveType === '2g' || effectiveType === 'slow-2g') {
//             alert('Your network seems slow. Please check your internet connection or try again later.');
//             // error({ statusCode: 404, message: 'Post not found' })
//             window.location.replace('/pages/404')
//         }
//     }
// };


export default ({ route, app }, inject) => {
    // Prevent the script from running on the 404 page
    if (route.path === '/pages/404') {
        return;
    }

    if (navigator.connection) {
        const { effectiveType } = navigator.connection;

        // Log the effective type of the network
        console.log('Effective connection type:', effectiveType);

        // Detect if the connection is slow
        const isSlowConnection = effectiveType === '2g' || effectiveType === 'slow-2g';

        // Inject the result into the app context
        inject('isSlowConnection', isSlowConnection);
    } else {
        // Default to false if navigator.connection is not supported
        inject('isSlowConnection', false);
    }
};

