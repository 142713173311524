<template>
  <div>
    <b-container>
      <div style="margin-top: 20%; text-align: center; margin-bottom: 20%;">
        <h1 v-if="error.statusCode === 404 || error.statusCode === 500">{{ $t('page.page_not_found.title') }}</h1>
        <h1 v-else>{{ $t('page.page_not_found.title') }}</h1>
        <h1>{{$t('page.page_not_found.page_not_found')}}</h1>
        <p>{{ $t('page.page_not_found.description') }}</p>
        <!-- <p v-if="customerSettingDetails.secure_status === 'A'">{{ $t('page.login.add_note') }}</p>
        <button v-if="customerSettingDetails.secure_status ==='A'" type="submit" class="btn btn-outline-primary-2 mt-3" @click="goback()">
          <span>{{ $t("page.login.relogin") }}</span>
        </button> -->
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

  export default {
  props: ['error'],
  
    // layout: '../layouts/error.vue',
  // layout: 'layoutb', // you can set a custom layout for the error page
  // async asyncData({ params, env, $axios, error, req, query, store, $t, app: { i18n } }) {
  //   return {
  //     username: env.AUTH_NAME,
  //     password: env.AUTH_KEY,
  //   }

  // },

  data: function () {
    return {
      merchantDetails: '',
      MerchantSettings:'',
      customerSettingDetails: '',
    }
  },

  async mounted() {
    await this.getMerchantDetails()
    await this.merchantSettings();
    await this.getCustomerSetting()
    var default_code = this.MerchantSettings.default_lang;
    console.log('default_code', default_code);
    if (default_code !== '') {
      if (default_code === 'ja') {
        this.async_locale = 'ja'
      }
      if (default_code === 'kr') {
        this.async_locale = 'kr'
      }
      if (default_code === 'th') {
        this.async_locale = 'th'
      }
      if (default_code === 'id') {
        this.async_locale = 'id'
      }
      if (default_code === 'zh_Hant') {
        this.async_locale = 'zh_Hant'
      }
      if (default_code === 'zh_Hans') {
        this.async_locale = 'zh_Hans'
      }
      if (default_code === 'en') {
        this.async_locale = 'en'
      }
    }
     var lang_code = this.$store.state.sessionStorage.select_lang;
        if (lang_code !== '') {
            if (lang_code === 'ja') {
                this.async_locale = 'ja'
            }
            if (lang_code === 'kr') {
                this.async_locale = 'kr'
            }
            if (lang_code === 'th') {
                this.async_locale = 'th'
            }
            if (lang_code === 'id') {
                this.async_locale = 'id'
            }
            if (lang_code === 'zh_Hant') {
                this.async_locale = 'zh_Hant'
            }
            if (lang_code === 'zh_Hans') {
                this.async_locale = 'zh_Hans'
            }
            if (lang_code === 'en') {
                this.async_locale = 'en'
            }
        }
        this.$store.commit('sessionStorage/assignLang', this.async_locale);
        console.log("this.$store.state.sessionStorage.lang123", this.$store.state.sessionStorage.lang);
    
    
    this.$i18n.locale = this.$store.state.sessionStorage.lang;
  },

  methods: {
    goback(){
      window.location.replace("/");
    },

    getMerchantDetails: async function () {
      var url = `/api/mzapps/merchant`;
      await this.$axios.$get(url).then(response => {
       this.merchantDetails = response;
        document.title = ` Coupon Wallet | ${this.merchantDetails.merchant.merchant_name}`

        console.log("merchantDetails", this.merchantDetails);
      }).catch(error => {
        console.log("error@@@", error);
      })
    },
    // <================================ Merchant Settings Api function ====================================>

    merchantSettings: async function () {
      var url = `/api/mzapps/settings?country_code=${this.country}&timestamp=${new Date().getTime()}`
      await this.$axios.$get(url).then(response => {
        console.log("merchantSettings ", response)
        this.MerchantSettings = response;
      }).catch(e => {
        console.log("customer Error ", e);

      })
    },
    getCustomerSetting: async function () {
      var url = `/api/mzapps/customersetting`;
     await this.$axios.$get(url).then(response => {
        this.customerSettingDetails = response.customer_setting;
        console.log("customerSettingDetails", this.customerSettingDetails);
      }).catch(e => {
        console.log("customer Error ", e);
      })
    },
  },
 
  }
</script>
<style>
    
</style>