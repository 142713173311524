<template>
    <div class="dropdown cart-dropdown"
        v-if="customerSettingDetails.secure_status === 'A' && customers_details.profile_status === 'A' && $store.state.sessionStorage.login_successfull ">
        <button class="dropdown-toggle">
            <i class="icon-user"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right"  key="hasCart">
            <div class="dropdown-cart-products">
                <ul >
                    <!-- <li class="product" style="border-bottom:none;">
                        <a href="#"   @click="ViewWallet()" class="product-title">{{$t('components.user_menu.coupon_wallet')}}</a>
                    </li> -->
                    <li class="product" style="border-bottom:none;padding:0px!important;">
                        <button @click.prevent="logout()"
                            class="product-title">{{$t('components.user_menu.sign_out')}}</button>
                    </li>

                    <!-- <li class="product" style="border-bottom:none;padding:0px!important;">
                        <button @click.prevent="updatePassword()" class="product-title">{{
                            $t('page.forgot.change_password') }}</button>
                    </li> -->

                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// import { mounted } from 'vueisotope';
import { PublicClientApplication } from '@azure/msal-browser';
import { mapGetters, mapActions } from 'vuex';
import { baseUrl } from '~/repositories/repository';
import crypto from "crypto";

function decrypt(messagebase64, keyBase64, ivBase64) {
    const key = Buffer.from(keyBase64, 'base64');
    const iv = Buffer.from(ivBase64, 'base64');
    const decipher = crypto.createDecipheriv(getAlgorithm(key), key, iv);
    let decrypted = decipher.update(messagebase64, 'hex');
    decrypted += decipher.final('utf8');
    return decrypted;
}

function getAlgorithm(key) {
    // var key = Buffer.from(keyBase64, 'base64');
    console.log("Key value", key, key.length)
    switch (key.length) {
        case 16:
            return 'aes-128-cbc';
        case 32:
            return 'aes-256-cbc';
    }
    throw new Error('Invalid key length: ' + key.length);
}

export default {
    
    data: function() {
        return {
            baseUrl: baseUrl,
            catalogueId:'',
            paymentDetails:'',
            catalogueDetails:'',
            CatalogueType:'PU',
            customers_details:'',
            // customer_id:null,
            customerSettingDetails: '',
            customer_id: '',
            environment: '',
            msal: null
        };
    },
    mounted: function () {
        this.environment = this.$store.state.sessionStorage.environment
        this.customer_id = this.$route.params.id
        // this.getActivePayment();
        this.getMerchantDetails();
        if (this.customer_id !== undefined && this.customer_id !== null) {
          this.GetCustomer();
        }

        
    },
    computed: {
        ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal'])
    },
    methods: {
        msalConfig(customerSettingDetails) {
            console.log("customerSettingDetails##", customerSettingDetails);
            if (customerSettingDetails) {
                const msalConfig = {
                    auth: {
                        clientId: customerSettingDetails.client_id,
                        authority: `https://${customerSettingDetails.tenant_name}.b2clogin.com/${customerSettingDetails.tenant_name}.onmicrosoft.com/${customerSettingDetails.user_flow_name}`,
                        knownAuthorities: [`${customerSettingDetails.tenant_name}.b2clogin.com`],
                        redirectUri: customerSettingDetails.azure_redirect_url //customerSettingDetails.azure_redirect_url,
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: true,
                    },
                };

                const msalInstance = new PublicClientApplication(msalConfig);
                console.log('MSAL instance directly in component:', msalInstance);
                this.msal = msalInstance;
            } else {
                console.error('customerSettingDetails is not available');
            }
        },
        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },
        ...mapActions('cart', ['removeFromCart']),

                            // <----------- Get Coustomer Details Api Function --------------->
        async GetCustomer() {

            console.log('customer_id',this.customer_id);
            await this.$axios.$get(`/api/mzapps/customer/${this.customer_id}`).then(response => {
                console.log('customer respponse', response);
                this.customers_details = response;
                // var customerGroupId = this.customers_details.customer_group_id
                console.log('customerGroupId', this.customers_details);
            }).catch(e => {
                console.log("customer Error ", e);
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'customer Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })

        },

        getMerchantDetails: function () {
            var url = `/api/mzapps/merchant/${this.$store.state.sessionStorage.merchant_id}?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                console.log('FoootermerchantDetails', this.merchantDetails);
                this.getCustomerSetting()
                // document.title = `Official E-Voucher Store | ${this.merchantDetails.merchant_name}`
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'merchant Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
        
        getCustomerSetting: async function () {
            var url = `/api/mzapps/customersetting`;
            this.$axios.$get(url).then(response => {
                this.customerSettingDetails = response.customer_setting;
                console.log("customerSettingDetails", this.customerSettingDetails);
                if (this.customerSettingDetails) {
                    
                     this.msalConfig(this.customerSettingDetails)

                    console.log("this.customerSettingDetails", this.customerSettingDetails);
                }
            }).catch(e => {
                console.log("customer Error ", e);
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'customersetting Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
    
                            //<---------------- logout Function ------------------>

        logout: function () {
            if (this.$store.state.sessionStorage.GenericLogin) {
                if(this.msal){
                    this.msal.logout();
                }
                window.location.replace('/')
                this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);  
            } else {
                if(this.msal){
                    this.msal.logout();
                }
                this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
            }
            
        },

        updatePassword() {
                // this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
                window.location.replace('/updatepassword')
        },

                            //<---------------- View Wallet Function ------------------>
                            
        // ViewWallet: function() {
        //     var wallet_url = this.customerS_details.wallet_url;
          
        //     window.open(wallet_url,"_blank")
           
        // }
    },
};
</script>
<style scoped>
.cart-dropdown .dropdown-menu.dropdown-menu-right{
    right: -22px !important;

}
.cart-dropdown .dropdown-menu{
width:210px !important;
}    

.cart-dropdown .product-title {
    font-size: 1.3rem !important;
    color: #000; 
    margin-top: 10px !important;
    
}

.cart-dropdown .product-title:hover{
    color: black;
}

@media only screen and (max-device-width: 991px) {
 .cart-dropdown {
    display: none !important;
 }
}

 
</style>