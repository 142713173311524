import crypto from "crypto";

export default async function ({ store, req, $axios, query, redirect, params, error }) {
    try {
        var data = params.id;
        console.log('customer_id@@@@@@@@', data);
            console.log("hello#1");
        redirect(`/${data}/shop/sidebar/list?lang=en`);
    } catch (err) {
        console.log('errorrrrrrr###', err);
        error({ statusCode: 404, message: 'Post not found' });
    }
}